
import Vue from 'vue';
import { empty } from '@/utils/helpers';
import Http, { HttpError } from '@/utils/Http';
import intl from '@/utils/intl';

export default Vue.extend({
  name: 'InvoicesTagsEditModal',
  props: {
    small: Boolean,
    dark: Boolean,
    color: String,
    cssClass: String,
    title: {
      type: String,
      required: true
    },
    id: [Number, String],
    type: String,
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false,
      loading: false,
      submitLoading: false,
      items: [],
      filteredItems: [],
      selectedTags: [] as string[]
    }
  },
  computed: {
    searchLabel(): string {
      return intl.t('views.tags.searchLabel');
    },
    closeButton(): string {
      return intl.t('buttons.close');
    },
    saveButton(): string {
      return intl.t('buttons.save');
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value: boolean): void {
        this.modal = value;
      }
    },
    async modal(value: boolean): Promise<void> {
      if (value) {
        await this.getItems();
      }
    }
  },
  methods: {
    searchItems(value: string | null): void {
      const localItems = [...this.items];
      if (value) {
        this.filteredItems = localItems.filter((str: string) => str.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      } else {
        this.filteredItems = localItems;
      }
    },
    async getItems(): Promise<void> {
      this.loading = true;
      this.items = [];
      this.filteredItems = [];
      this.selectedTags = [];
      try {
        const response = await (new Http()).get(`/v1/invoices/${this.type}/${this.id}/tags`);
        if (!empty(response.data.available_tags)) {
          this.items = response.data.available_tags;
          this.filteredItems = response.data.available_tags;
        }
        if (!empty(response.data.selected_tags)) {
          this.selectedTags = response.data.selected_tags;
        }
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.loading = false;
    },
    close(): void {
      this.modal = false;
      this.$emit('input', false);
    },
    async save(): Promise<void> {
      this.submitLoading = true;
      try {
        await (new Http()).put(`/v1/invoices/${this.type}/${this.id}/tags`, JSON.stringify(this.selectedTags));
        this.$root.$children[0].$children[0].setFlash('success', intl.t('views.tags.saveMessage'));
        this.close();
        this.$emit('success', this.selectedTags);
      } catch (error) {
        const err = (error as HttpError).response?.data.error;
        this.$root.$children[0].$children[0].setFlash('error', err.message);
      }
      this.submitLoading = false;
    }
  }
});
